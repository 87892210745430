<template>
  <div>
    <Head :title="$t('Vote.title')"></Head>
    <div class="head center" :style="'width:'+$setWidth">
      <img src="@/image/vote_bg.jpg" style="width:100%;" alt="">
      <div class="head_info">
        <div class="head_title">MTDAO</div>
        <div class="head_text">{{$t('Vote.text1')}}</div>
      </div>
    </div>
    <div class="center" style="padding:0 10px;" :style="'width:'+$setWidth">
      <!-- 数 -->
      <div class="LP_box">
        <div class="info flex_pj">
          <div class="y_center">
            <div class="info_num">{{info.participants||0}}</div>
            <div class="info_text">{{$t('Vote.text2')}}</div>
          </div>
          <p/>
          <div class="y_center">
            <div class="info_num">{{info.participants||0}}</div>
            <div class="info_text">{{$t('Vote.text3')}}</div>
          </div>
          <p/>
          <div class="y_center">
            <div class="info_num">{{info.info.count||0}}</div>
            <div class="info_text">{{$t('Vote.text4')}}</div>
          </div>
        </div>
        <div class="vote_status flex_center" :class="countTime(time,info.info.start_timestamp).time>0?'vote_status_close':countTime(time,info.info.end_timestamp).time>0?'vote_status_open':'vote_status_close'">{{countTime(time,info.info.start_timestamp).time>0?$t('Vote.text13'):countTime(time,info.info.end_timestamp).time>0?$t('Vote.text5'):$t('Vote.text6')}}</div>
      </div>
      <!-- 投票 -->
      <div class="LP_box y_center">
        <div class="vote_time" v-if="countTime(time,info.info.start_timestamp).time>0"><span>{{countTime(time,info.info.start_timestamp).d||'00'}}</span>{{$t('Vote.d')}}<span>{{countTime(time,info.info.start_timestamp).h||'00'}}</span>{{$t('Vote.h')}}<span>{{countTime(time,info.info.start_timestamp).m||'00'}}</span>{{$t('Vote.m')}}<span>{{countTime(time,info.info.start_timestamp).s||'00'}}</span>{{$t('Vote.s')}}</div>
        <div class="vote_time" v-if="countTime(time,info.info.start_timestamp).time<=0"><span>{{countTime(time,info.info.end_timestamp).d||'00'}}</span>{{$t('Vote.d')}}<span>{{countTime(time,info.info.end_timestamp).h||'00'}}</span>{{$t('Vote.h')}}<span>{{countTime(time,info.info.end_timestamp).m||'00'}}</span>{{$t('Vote.m')}}<span>{{countTime(time,info.info.end_timestamp).s||'00'}}</span>{{$t('Vote.s')}}</div>
        <div class="vote_text">{{info.info.title}}</div>
        <div class="topics">
					<div class="flex_row" style="width: 100%;">
						<div class="topics_yes flex_row hand" @click="vote(1)">
							<div class="topics_yes_img flex_center">
								<i class="ifont icone-dianzan1"></i>
							</div>
							<div class="topics_yes_box flex_center">{{$t('Vote.btn1')}}</div>
						</div>
						<div class="topics_pk">PK</div>
						<div class="topics_no flex_row hand" @click="vote(2)">
							<div class="topics_no_img flex_center">
								<i class="ifont icone-dianzan1"></i>
							</div>
							<div class="topics_no_box flex_center">{{$t('Vote.btn2')}}</div>
						</div>
					</div>
					<div class="topics_info flex_pj">
						<div style="width: 35%;">{{$t('Vote.text7')}}({{info.support||0}})</div>
            <div style="width: 30%">
              <div v-if="info.is_vote==1">{{$t('Vote.text10')}}</div>
            </div>
						<div style="width: 35%;">{{$t('Vote.text8')}}({{info.oppose||0}})</div>
					</div>
				</div>
      </div>
      <!-- 活动规则 -->
      <div class="LP_box y_center">
        <div class="rule_title flex_row"><p/>{{$t('Vote.text9')}}<p/></div>
        <div class="rule_text" style="width: 100%;" v-html="zifu(info.info.content)"></div>
      </div>
    </div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
export default {
  data () {
    return {
      id:0,
      address:'',
      info:{
        info:{}
      },
      time:0,
      interval:''
    }
  },
  computed: {
    userInfo() {
			return this.$store.state.user.userInfo
		},
	},
  mounted () {
    this.id = this.$route.query.id
    this.address = walletHelper.getAddress()
    this.init()
  },
  methods: {
    init() {
      this.get('api/Vote/info', {id:this.id,wallet:this.address}, 'POST').then(res => {
        this.info = res
        this.time = res.time
        if(this.interval==''){
          this.interval = setInterval(()=>{
            this.time++
          },1000)
        }
      })
    },
    countTime(starttime,endtime){
      var start_time = new Date(starttime).getTime()*1000
      var end_time = new Date(endtime).getTime()*1000
      var time = end_time-start_time
      var d = 0
      var h = 0
      var m = 0
      var s = 0
      if(time<=0){
        time = 0
        d = '00'
        h = '00'
        m = '00'
        s = '00'
      }
      d = Math.trunc(time / (1000 * 60 * 60 * 24));
      h = parseInt(time % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      m = parseInt(time % (1000 * 60 * 60) / (1000 * 60));
      s = time % (1000 * 60) / 1000;
      if (d < 10) {
        d = '0' + d
      }
      if (h < 10) {
        h = '0' + h
      }
      if (m < 10) {
        m = '0' + m
      }
      if (s < 10) {
        s = '0' + s
      }
      var time_arr={
        d:d,
        h:h,
        m:m,
        s:s,
        time:time
      }
      return time_arr
    },
    vote(status){
      if(this.info.is_vote==1){
        this.$toast(this.$t('Vote.text11'))
        return
      }
      walletHelper.getContract('rel').methods.getPressureNftTokenId(walletHelper.getAddress()).call().then(res=>{
        if(Number(res)>0){
          this.get('api/Vote/vote', {vote_id:this.id,wallet:this.address,is_option:status}, 'POST').then(res => {
            this.$toast(this.$t('Vote.text12'))
            this.init()
          })
        }else{
          this.$toast('暂无投票资格')
        }

      })
    },
    zifu(value){
      if(value!=null){
        value = value.replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'")
            .replace(/&nbsp;/g, ' ')
        return value
      }else{
        return value
      }
    },
    copy(){
      this.$copyText(this.addressToken).then(e=>{
        this.$toast(this.$t('tips.title2'))
      }, e=> {
        this.$toast(this.$t('tips.title3'))
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .head{
    position: relative;
    .head_info{
      position: absolute;
      top: 20%;
      left: 30px;
      color: #fff;
      font-family: Adobe Heiti Std;
      .head_title{
        font-weight: bold;
        font-size: 2.5rem;
      }
      .head_text{
        font-size: 1rem;
        margin-top: 10px;
      }
    }
  }
  .LP_box{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  .info{
    .info_num{
      font-size: 1.2rem;
      font-family: Adobe Heiti Std;
      color: #00EAFF;
    }
    .info_text{
      color: #ffffff;
      font-size: 0.7rem;
      margin-top: 3px;
    }
    p{
      width: 1px;
      height: 30px;
      background: #ffffff;
    }
  }
  .vote_status{
    width: 100%;
    height: 35px;
    font-size: 1rem;
    margin: 10px 0;
    color: #ffffff;
  }
  .vote_status_open{
    background: linear-gradient(to right, transparent 0%, rgba(0, 234, 255, 0.6) 15% 85%, transparent 100%);
  }
  .vote_status_close{
    background: linear-gradient(to right, transparent 0%, rgba(0, 234, 255, 0.3) 15% 85%, transparent 100%);
  }
  .vote_time{
    font-size: 0.9rem;
    margin: 10px 0;
    color: #ffffff;
    span{
      margin: 0 5px;
      padding: 2px 5px;
      background: #00EAFF;
      color: #000;
      font-weight: bold;
    }
  }
  .vote_text{
    color: #ffffff;
    font-size: 1rem;
    padding: 5px 0;
  }
  .topics{
    width: 100%;
    padding: 10px 0;
		i{
			font-size: 1.8rem;
			color: #FFFFFF;
		}
    .topics_yes{
      width: 43%;
      height: 50px;
      position: relative;
      // filter: drop-shadow(0px 0px 10px rgba(255, 0, 0, 1));
      .topics_yes_img{
        width: 50px;
        height: 50px;
        background: #25C70F;
        box-shadow: 0px 0px 20px 0px rgba(103, 18, 16, 0.36);
        border-radius: 50%;
        position: absolute;
        left: 0;
        z-index: 2;
      }
      .topics_yes_box{
        background-color: #25C70F;
        padding-left: 30px;
        width: 100%;
        height: 35px;
        font-size: 0.9rem;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        z-index: 1;
        clip-path:polygon(20px 0,20px 100%,calc(100% - 20px) 100%,100% 0);
      }
    }
    .topics_pk{
      width: 14%;
      text-align: center;
      font-size: 1.5rem;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
    }
    .topics_no{
      width: 43%;
      height: 50px;
      position: relative;
      // filter: drop-shadow(0px 0px 10px rgba(24, 142, 225, 1.0));
      .topics_no_img{
        width: 50px;
        height: 50px;
        background: #FF0000;
        box-shadow: 0px 0px 20px 0px rgba(103, 18, 16, 0.36);
        border-radius: 50%;
        position: absolute;
        right: 0;
        z-index: 2;
        transform: rotateY(180deg) rotate(180deg);
      }
      .topics_no_box{
        background-color: #FF0000;
        padding-right: 30px;
        width: 100%;
        height: 35px;
        font-size: 0.9rem;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
        z-index: 1;
        clip-path:polygon(20px 0,0 100%,calc(100% - 10px) 100%,calc(100% - 10px) 0);
      }
		}
    .topics_info{
      padding: 0 15px;
      width: 100%;
      text-align: center;
      font-size: 0.9rem;
      font-family: SourceHanSansCN;
      color: #ffffff;
      text-align: center;
    }
	}
  .rule_title{
    font-size: 1.1rem;
    font-family: Microsoft YaHei;
    color: #00FFEA;
    margin-bottom: 20px;
    p{
      width: 30px;
      height: 1px;
      background: #00FFEA;
      margin: 0 10px;
    }
  }
  .rule_text{
    color: #00FFEA;
    font-size: 0.9rem;
  }
</style>